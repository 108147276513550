import { Org } from "@ollie-sports/models";
import * as express from "express";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../internal-utils/server-auth";
import { getUniversalHelpers } from "../helpers";
import * as _ from "lodash";

export function other__local__getOllieFirestoreStats(): any {
  try {
    return getUniversalHelpers().ollieFirestoreV2._GetStats();
  } catch (e) {
    return {};
  }
}

export function other__local__getOllieFirestoreStatsSummary(): any {
  try {
    const v: any = getUniversalHelpers().ollieFirestoreV2._GetStats();
    const collectionKeys = Object.keys(v.byCollection);
    for (let i = 0; i < collectionKeys.length; i++) {
      const key: any = collectionKeys[i];
      delete v.byCollection[key].activeSubscriptions;
      delete v.byCollection[key].statsInitMS;
    }

    return v;
  } catch (e) {
    console.error(e);
    return {
      errorForFirestoreSummary: JSON.stringify(e)
    };
  }
}

export async function other__server__getStartupData(p: { selfAccountId: string }): Promise<{ adminOrgs: Org[] }> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  let adminOrgsResult = await h.Org.query({ where: [{ accounts: { [p.selfAccountId]: { exists: ["==", true] } } }] });

  return {
    adminOrgs: adminOrgsResult.docs
  };
  // SERVER_ONLY_TOGGLE
}
other__server__getStartupData.auth = async (r: express.Request) => {
  await validateTokenAndEnsureSelfAccountIdMatches(r);
  // Make sure token valid
  // Make sure req.body.selfAccountId matches auth token
};

// i18n certified - complete
